import React from "react";

export const Speedometer = ({ vel }) => {
  let deg = 3.6 * vel;
  if (!vel) deg = 0;
  if (vel >= 50) deg = 180;
  const style = {
    transformOrigin: "center",
    transform: `rotate(${deg}deg)`,
  };
  return (
    <div className="speedometer-general">
      <svg
        width="30"
        height="30"
        viewBox="0 0 531 531"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect id="Rectangle 7" width="531" height="531" fill="white" />
        <g id="arc" style={style}>
          <g id="Ellipse 1">
            <mask id="path-2-inside-1" fill="white">
              <path d="M36.9996 265.5C36.9996 235.493 42.9099 205.78 54.3931 178.057C65.8763 150.334 82.7075 125.144 103.926 103.926C125.144 82.7078 150.334 65.8766 178.057 54.3934C205.779 42.9102 235.493 36.9998 265.5 36.9998C295.507 36.9998 325.22 42.9102 352.943 54.3934C380.666 65.8766 405.856 82.7078 427.074 103.926C448.292 125.144 465.123 150.334 476.607 178.057C488.09 205.78 494 235.493 494 265.5L471.15 265.5C471.15 238.494 465.831 211.752 455.496 186.801C445.161 161.851 430.013 139.18 410.916 120.083C391.82 100.987 369.149 85.8389 344.199 75.5041C319.248 65.1692 292.506 59.8499 265.5 59.8499C238.493 59.8499 211.752 65.1692 186.801 75.504C161.85 85.8389 139.18 100.987 120.083 120.083C100.987 139.18 85.8387 161.851 75.5038 186.801C65.1689 211.752 59.8496 238.494 59.8496 265.5H36.9996Z" />
            </mask>
            <path
              d="M36.9996 265.5C36.9996 235.493 42.9099 205.78 54.3931 178.057C65.8763 150.334 82.7075 125.144 103.926 103.926C125.144 82.7078 150.334 65.8766 178.057 54.3934C205.779 42.9102 235.493 36.9998 265.5 36.9998C295.507 36.9998 325.22 42.9102 352.943 54.3934C380.666 65.8766 405.856 82.7078 427.074 103.926C448.292 125.144 465.123 150.334 476.607 178.057C488.09 205.78 494 235.493 494 265.5L471.15 265.5C471.15 238.494 465.831 211.752 455.496 186.801C445.161 161.851 430.013 139.18 410.916 120.083C391.82 100.987 369.149 85.8389 344.199 75.5041C319.248 65.1692 292.506 59.8499 265.5 59.8499C238.493 59.8499 211.752 65.1692 186.801 75.504C161.85 85.8389 139.18 100.987 120.083 120.083C100.987 139.18 85.8387 161.851 75.5038 186.801C65.1689 211.752 59.8496 238.494 59.8496 265.5H36.9996Z"
              stroke="#DBDFF4"
              stroke-width="36"
              mask="url(#path-2-inside-1)"
            />
          </g>
          <g id="Ellipse 2">
            <mask id="path-3-inside-2" fill="white">
              <path d="M494 265.5C494 326.102 469.926 384.222 427.074 427.074C384.222 469.926 326.102 494 265.5 494C204.898 494 146.778 469.926 103.926 427.074C61.0736 384.222 36.9996 326.102 36.9995 265.5L59.8496 265.5C59.8496 320.042 81.5162 372.35 120.083 410.917C158.65 449.484 210.958 471.15 265.5 471.15C320.042 471.15 372.349 449.484 410.916 410.917C449.483 372.35 471.15 320.042 471.15 265.5H494Z" />
            </mask>
            <path
              d="M494 265.5C494 326.102 469.926 384.222 427.074 427.074C384.222 469.926 326.102 494 265.5 494C204.898 494 146.778 469.926 103.926 427.074C61.0736 384.222 36.9996 326.102 36.9995 265.5L59.8496 265.5C59.8496 320.042 81.5162 372.35 120.083 410.917C158.65 449.484 210.958 471.15 265.5 471.15C320.042 471.15 372.349 449.484 410.916 410.917C449.483 372.35 471.15 320.042 471.15 265.5H494Z"
              stroke="#FFAD47"
              stroke-width="36"
              mask="url(#path-3-inside-2)"
            />
          </g>
        </g>
        <rect id="Rectangle 8" y="265" width="531" height="266" fill="white" />
        <g id="arrow" style={style}>
          <path
            id="Rectangle 1"
            d="M113 271V261L266 247V283L113 271Z"
            fill="#DBDFF4"
          />
          <circle
            id="Ellipse 3"
            cx="265"
            cy="265"
            r="22"
            transform="rotate(-90 265 265)"
            fill="#DBDFF4"
          />
        </g>
        <g id="fundos">
          <rect
            id="Rectangle 2"
            x="90"
            y="83.2836"
            width="30"
            height="68"
            transform="rotate(-40 90 83.2836)"
            fill="white"
          />
          <rect
            id="Rectangle 3"
            x="429.71"
            y="64"
            width="30"
            height="68"
            transform="rotate(40 429.71 64)"
            fill="white"
          />
          <rect
            id="Rectangle 5"
            x="32"
            y="197.544"
            width="30"
            height="68"
            transform="rotate(-80 32 197.544)"
            fill="white"
          />
          <rect
            id="Rectangle 6"
            x="493.967"
            y="168"
            width="30"
            height="68"
            transform="rotate(80 493.967 168)"
            fill="white"
          />
          <rect
            id="Rectangle 4"
            x="250"
            y="13"
            width="30"
            height="68"
            fill="white"
          />
        </g>
      </svg>
    </div>
  );
};
